import React, { useState, useEffect, useRef } from "react";
import useHttp from "../hooks/useHttp";

const TableModal = ({ id, onClose }) => {
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [_, handleHttpRequest] = useHttp();
  const [inventoryCountdetail, setInventoryCountdetail] = useState();
  const fetchedIds = useRef(new Set());

  const getIngredient = async (ingredientId) => {
    if (!ingredientId || fetchedIds.current.has(ingredientId)) return;
    fetchedIds.current.add(ingredientId);

    setLoading(true);
    setError(null);

    try {
      const response = await handleHttpRequest({
        page: `ingredient/${ingredientId}`,
        method: "GET",
      });

      const counts = await handleHttpRequest({
        page: `ingredient-inventorycount/${ingredientId}`,
      });

      const detail = await handleHttpRequest({
        page: `ingredient-inventorycountdetail/${ingredientId}`,
        method: "GET",
      });

      if (!detail.status) {
        setInventoryCountdetail(detail);
      }
      if (response.status === 200) {
        setData(response);
        setCount(isNaN(Number(counts)) ? 0 : Number(counts));
      } else {
        setError("Failed to fetch ingredient details.");
      }
    } catch (err) {
      setError("An error occurred while fetching ingredient details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getIngredient(id);
    }
  }, [id]);

  const closeModalOnClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex="-1"
      onClick={closeModalOnClickOutside}
      role="dialog"
      aria-labelledby="modal-title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content shadow-lg">
          <div className="modal-header bg-dark text-white">
            <h5 className="modal-title" id="modal-title">
              {loading ? "Loading..." : "Inventory Details"}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {loading ? (
              <div className="placeholder-glow">
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
              </div>
            ) : error ? (
              <div className="alert alert-danger text-center">{error}</div>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th className="table-light" style={{ width: "30%" }}>Item Name</th>
                      <td>{data?.message?.name || "N/A"}</td>
                    </tr>
                    <tr>
                      <th className="table-light">Category</th>
                      <td>{data?.message?.category?.name || "Not set"}</td>
                    </tr>
                    <tr>
                      <th className="table-light">Unit Measure</th>
                      <td>{data?.message?.default_unit_measure || "Not set"}</td>
                    </tr>
                    <tr>
                      <th className="table-light">Price</th>
                      <td>${data?.message?.default_unit_price || "N/A"}</td>
                    </tr>
                    <tr>
                      <th className="table-light">Storage Location</th>
                      <td>
                        {data?.storage_location?.length > 0 ? (
                          data.storage_location.map((location, index) => (
                            <span key={index} className="badge bg-primary m-1">
                              {location.name} ({inventoryCountdetail?.[location.name] || 0})
                            </span>
                          ))
                        ) : (
                          "-----"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="table-light">Primary Supplier</th>
                      <td>
                        {data?.message?.primary_supplier?.name ? (
                          <span className="badge bg-success">{data.message.primary_supplier.name}</span>
                        ) : (
                          "-----"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="table-light">Secondary Supplier</th>
                      <td>
                        {data?.message?.secondary_supplier?.name ? (
                          <span className="badge bg-info">{data.message.secondary_supplier.name}</span>
                        ) : (
                          "-----"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="table-light">Onhand Qty</th>
                      <td>
                        <strong>{count ?? 0}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
              aria-label="Close modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableModal;
