import React, { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";

const RecipeModal = ({ id, onClose }) => {
  const [recipe, setRecipe] = useState(null);
  const [recipeItems, setRecipeItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [_, handleHttpRequest] = useHttp();

  const getRecipeDetails = async (recipeId) => {
    if (!recipeId) return;
    
    setLoading(true);
    setError(null);

    try {
      const response = await handleHttpRequest({
        page: `recipe/${recipeId}`,
        method: "GET",
      });
      const recipe = await handleHttpRequest({
        page: `recipeitem/recipe/${recipeId}`,
        method: "GET",
      });
      
      if (response && recipe) {
        setRecipe(response);
        setRecipeItems(recipe);
      } else {
        setError("Failed to fetch recipe details.");
      }
    } catch (err) {
      setError("An error occurred while fetching recipe details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getRecipeDetails(id);
    }
  }, [id]);

  const closeModalOnClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex="-1"
      onClick={closeModalOnClickOutside}
      role="dialog"
      aria-labelledby="modal-title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content shadow-lg">
          <div className="modal-header bg-dark text-white">
            <h5 className="modal-title" id="modal-title">
              {loading ? "Loading..." : "Recipe Details"}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {loading ? (
              <div className="placeholder-glow">
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
              </div>
            ) : error ? (
              <div className="alert alert-danger text-center">{error}</div>
            ) : recipe ? (
              <>
                {/* Recipe Summary */}
                <div className="table-responsive mb-3">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th className="table-light" style={{ width: "30%" }}>Recipe Name</th>
                        <td>{recipe.name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th className="table-light">Yield</th>
                        <td>{`${recipe.yeild_qty} ${recipe.yeild_measure}`}</td>
                      </tr>
                      <tr>
                        <th className="table-light">Total Cost</th>
                        <td>${recipe.recipe_cost.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th className="table-light">Unit Cost</th>
                        <td>${recipe.recipe_cost_per.toFixed(2)}</td>
                      </tr>                      
                    </tbody>
                  </table>
                </div>

                {/* Ingredient List */}
                <h5 className="mb-3">Ingredients</h5>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Unit Cost</th>
                        <th>Extended Cost</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {recipeItems.length > 0 ? (
                        recipeItems.map((item) => (
                          <tr key={item.id}>
                            <td>{item.ingredient.name}</td>
                            <td>{item.unit_size} {""}{item.unit_measure}</td>
                            <td>{item.cost_per_unit}</td>
                            <td>${Number(item.recipe_cost).toFixed(2)}</td>
                            </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No ingredients found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <p className="text-center">No recipe details available.</p>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
              aria-label="Close modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;
