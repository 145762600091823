import React, { useEffect, useState } from "react";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { useParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import TableModal from "../../components/TableModal";

const SupplierDetail = () => {
  const { id } = useParams();
  const [hasError, handleHttpRequest] = useHttp();

  const [initialValues, setInitialValues] = useState({
    name: "",
    account: "",
    address: "",
    note: "",
    sales_person: "",
    email: "",
    phone: "",
    sales_person_email: "",
    sales_person_phone: "",
    ar_email: "",
    ar_phone: "",
  });
  const [supplierIngredients, setSupplierIngredients] = useState([]);

  const getSupplier = async (id) => {
    try {
      const response = await handleHttpRequest({
        page: "supplier/detail/" + id,
        method: "GET",
      });
      setInitialValues(response.message);
      response.message.suppliercontacts.forEach((element) => {
        switch (element.type) {
          case "supplier": {
            setInitialValues((prev) => {
              return { ...prev, email: element.email, phone: element.phone };
            });
            break;
          }
          case "sales_person": {
            setInitialValues((prev) => {
              return {
                ...prev,
                sales_person_email: element.email,
                sales_person_phone: element.phone,
              };
            });
            break;
          }
          case "ar": {
            setInitialValues((prev) => {
              return {
                ...prev,
                ar_email: element.email,
                ar_phone: element.phone,
              };
            });
            break;
          }
        }
      });

      setSupplierIngredients(response.message.supplieringredient);
    } catch (err) {
      console.error("Error", err);
    }
  };

  useEffect(() => {
    getSupplier(id);
  }, [id]);

  const COLUMNS = [
    {
      Header: "Item Description",
      accessor: "supplier_item_desc",
    },
    {
      Header: "Item Number",
      accessor: "supplier_item_number",
    },
    {
      Header: "Sales By",
      accessor: "sold_by",
    },
    {
      Header: "Price per Default Item",
      accessor: "price_per_item",
      Cell: ({ value }) => "$" + value,
    },
  ];

  const formatPhoneNumber = (phone) => {
    const match = phone?.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title text-uppercase">{initialValues.name}</h4>
          </div>
        </div>
        <div className="card-body" style={{ padding: "1em 3em" }}>
          <ul className="nav nav-tabs" id="supplierTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details"
                type="button"
                role="tab"
                aria-controls="details"
                aria-selected="true"
              >
                Supplier Details
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="ingredients-tab"
                data-bs-toggle="tab"
                data-bs-target="#ingredients"
                type="button"
                role="tab"
                aria-controls="ingredients"
                aria-selected="false"
              >
                Supplier Items
              </button>
            </li>
          </ul>
          <div className="tab-content mt-4" id="supplierTabContent">
            <div
              className="tab-pane fade show active"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Supplier Name:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.name}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Address:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.address}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Phone Number:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{formatPhoneNumber(initialValues.phone)}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Email:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.email}</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Account:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.account}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Supplier notes:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.note}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Sales Person Representative</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Name:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.sales_person}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Email:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.sales_person_email}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Phone Number:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{formatPhoneNumber(initialValues.sales_person_phone)}</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>AR Contact Information</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Phone Number:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{formatPhoneNumber(initialValues.ar_phone)}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-end">
                      <label className="form-label lblrequired">Email:</label>
                    </div>
                    <div className="col-lg-3 col-md-6 text-start">
                      <label className="form-label">{initialValues.ar_email}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="ingredients"
              role="tabpanel"
              aria-labelledby="ingredients-tab"
            >
              <div className="toolbar"></div>
              <div className="row mt-4">
                <div className="tab-content mt-4" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="supplieringreds-tab-pane"
                    role="tabpanel"
                    aria-labelledby="supplieringreds-tab"
                    tabIndex="0"
                  >
                    <Table data={supplierIngredients} columns={COLUMNS} ModalComponent={TableModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainPanel>
  );
};

export default SupplierDetail;
