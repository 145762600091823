import React, { useState, useMemo, useEffect } from "react";
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import SelectIngredient from "../menuItems/SelectIngredients";
import useConversion from "../../hooks/useConversion";
import { IoIosSave, IoIosCreate, IoIosTrash, IoIosAdd } from "react-icons/io";
import TableModal from "../../components/TableModal";

const AddRecipeItem = ({ recipe_id, saveClick }) => {

  const [hasError, handleHttpRequest] = useHttp();
  const [IsStandardUnit] = useConversion();
  const [rows, setRows] = useState([]);
  const [newRowId, setNewRowId] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const getIngredients = async () => {    
    try {
      const response = await handleHttpRequest({
        page: "ingredients",
        method: "GET",
      });
      if (!hasError && response) {
        setIngredients(response.message);
      }
    } catch (error) {
      console.error("Error fetching ingredients:", error);
      setIngredients([]);
    } finally {    
    }
  };
  const getRecipes = async () => {
    try {
      const response = await handleHttpRequest({
        page: `recipeitem/recipe/${recipe_id}`,
        method: "GET",
      });
  
      console.log("Response:", response); // Debugging: Check if response contains expected data
  
      if (response ) {
        const savedItems = response;
        if (!savedItems || savedItems.length === 0) return ;
  
        // Format the data for the table
        const formattedRows = savedItems.map(item => ({
          id: item.id,
          ingredient_id:item.ingredient_id,
          ingredient_name: item.ingredient.name,
          unit_measure: item.unit_measure,
          unit_size: item.unit_size,
          cost_per_unit: item.cost_per_unit,
          recipe_cost: item.recipe_cost,
          isEditing: true,
          isNew: false,
        }));
  
        // Update the table rows
        setRows(formattedRows);
        // setTotalCostIngRec(response.total_cost);
      }
    } catch (error) {
      console.error("Error fetching menu item:", error);
    }
  };
  
  useEffect(() => {
    if (recipe_id) {
      getRecipes();
      getIngredients();
    }
  }, [recipe_id]);
  const columns = useMemo(() => [
     {
      accessorKey: "ingredient_name",
      header: "Ingredient",
      cell: ({ row }) => row.original.isEditing ? (
        
          <SelectIngredient
            value={row.original.ingredient_id}
            setUnitMeasure={(unit) => handleUnitChange(row.original.id, unit)}
            onChange={(e) => handleItemChange(row.original.id, e.target.value)}
            ingredients={ingredients}
          />
        
      ) : (
        <span>
          {row.original.ingredient_name || "N/A"}
        </span>
      )
      
    },
    
    {
      accessorKey: "unit_measure",
      header: "UOM",
      cell: ({ row }) => {
        const currentUnit = row.original.unit_measure;
        const isStandard = IsStandardUnit(currentUnit) === "Y";
        
        return row.original.isEditing ? (
          <select
            value={currentUnit}
            onChange={(e) => handleUnitChange(row.original.id, e.target.value)}
            className="form-select"
          >
            <option value="">Select Unit</option>
            {isStandard ? (
              units.map(unit => (
                <option key={unit.id} value={unit.unit}>{unit.name}</option>
              ))
            ) : (
              units.filter(unit => unit.unit === currentUnit).map(unit => (
                <option key={unit.id} value={unit.unit}>{unit.name}</option>
              ))
            )}
          </select>
        ) : (
          <span>{units.find(u => u.unit === currentUnit)?.name}</span>
        );
      },
    },
    {
      accessorKey: "unit_size",
      header: "Quantity",
      cell: ({ row }) => row.original.isEditing ? (
        <QuantityCell row={row} handleQuantityChange={handleQuantityChange} />
      ) : (
        <span>{row.original.unit_size}</span>
      ),
    },
    {
      accessorKey:"cost_per_unit",
      header:'Unit Cost',
      cell:({row})=>`$${row.original.cost_per_unit || 0}`
    },
    {
      accessorKey: "recipe_cost",
      header: "Extended Cost",
      cell: ({ row }) => {
        const cost = row.original.recipe_cost;
        // Default to 0.00 if cost is undefined or NaN
        const formattedCost = cost !== undefined && !isNaN(cost) 
          ? `$${Number(cost).toFixed(2)}` 
          : "$0.00";
        return <span>{formattedCost}</span>;
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div className="d-flex gap-2">
          {row.original.isNew ? (
            <>
              <button
                className="btn btn-sm btn-success"
                onClick={() => handleSaveRow(row.original.id)}
              >
                <IoIosSave />
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleCancel(row.original.id)}
              >
                ✕
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => toggleEdit(row.original.id)}
              >
                <IoIosCreate />
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleDeleteRow(row.original.id)}
              >
                <IoIosTrash />
              </button>
              <button 
  className="btn btntheme" 
  onClick={() => handleClick(row.original.ingredient_id)}
>
  Detail
</button>
            </>
          )}
        </div>
      ),
    },
  ], [IsStandardUnit, rows]);
  
  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  
  const addNewRow = () => {
    const isDuplicate = rows.some(row => row.ingredient_id === newRowId); 
    if (isDuplicate) {
      alert("This item has already been added.");
      return;
    }
    const newRow = {
      id: newRowId,    
      ingredient_id:"",
      ingredient_name: "",
      unit_measure: "",
      unit_size: "",
      cost_per_unit: 0,
      recipe_cost: 0,
      isEditing: true,
      isNew: true,
    };
    setRows([...rows, newRow]);
    setNewRowId(prev => prev + 1);
  };
  
  
  const handleSaveRow = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    if (!row.ingredient_id || !row.unit_size || !row.unit_measure) {
      alert("Please fill all required fields");
      return;
    }
  
    try {
      const endpoint = "recipeitem/add";
      
      const response = await handleHttpRequest({
        page: endpoint,
        method: "POST",
        data: { ...row,recipe_id    },
      });
  
      if (response) {
        saveClick(response);
        getRecipes(); // Refresh list
      } else {
        alert("Failed to save item.");
      }
    } catch (error) {
      alert("Error saving item");
    }
  };
  
  
  const handleDeleteRow = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    console.log(rows);
    if (row.isNew) {
      setRows(prev => prev.filter(r => r.id !== rowId));
      return;
    }
    const endpoint =`recipeitem/${row.id}`;
    try {
     const response= await handleHttpRequest({
        page: endpoint,
        method: "DELETE",
        
      });
      saveClick(response);
      getRecipes();
    } catch (error) {
      alert("Error deleting item");
    }
  };
  
  const toggleEdit = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    console.log(rows);
    if (row.isNew) {
      setRows(prev => prev.filter(r => r.id !== rowId));
      return;
    }
  
    try {
      const endpoint = `recipeitem/edit/${row.id}`;  // For Ingredient
      
      const requestData = {
        ingredient_id: row.ingredient_id,
        recipe_id: recipe_id,
        unit_size: row.unit_size,
        unit_measure: row.unit_measure,
      };
    
      
      // Sending the request
     const response= await handleHttpRequest({
        page: endpoint,
        method: "PUT",
        data: requestData,
      });
    if(response){
      saveClick(response);
      getRecipes();
    }
    } catch (error) {
      alert("Error updating item");
    }
    
  };
  
  
  const handleCancel = (rowId) => {
    setRows(prev => prev.filter(row => 
      row.isNew ? row.id !== rowId : true
    ));
  };
  
  
  
  const handleItemChange = (rowId, ingredient_id) => {
    
    setRows(prev => prev.map(row => 
      row.id === rowId ? { ...row, ingredient_id } : row
    ));
  };
  
  const handleUnitChange = (rowId, unit_measure) => {
    setRows(prev => prev.map(row => 
      row.id === rowId ? { ...row, unit_measure } : row
    ));
  };
  
  const handleQuantityChange = (rowId, unit_size) => {
    setRows(prev => prev.map(row => 
      row.id === rowId ? { ...row, unit_size } : row
    ));
  };
  
   const [showDetail, setShowDetail] = useState(false);
    const [ingredientId, setIngredientId] = useState(null);
  
    const handleClick = (id) => {
      setIngredientId(id);
      setShowDetail(true);  // Show the ingredient detail when button is clicked
    };
    
    const QuantityCell = React.memo(({ row, handleQuantityChange }) => {
      const [localQuantity, setLocalQuantity] = useState(row.original.unit_size);
    
      const handleBlur = async () => {
        await handleQuantityChange(row.original.id, localQuantity);
      };
      
    return (
      <input
        className="form-control"
        value={localQuantity}
        onChange={(e) => setLocalQuantity(e.target.value)}
        onBlur={handleBlur}
      />
    );
  });
    
  return (
    <>
    <div className="modal-body">
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className={row.original.isNew ? "table-light" : "table-light"}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td colSpan={columns.length}>
                <div className="d-flex justify-content-start mb-3">
                  <button className="btn btn-primary" onClick={addNewRow} disabled={rows.some(row => row.isNew || rows.length >= 10)}>
                    <IoIosAdd /> Add Row
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    
       {/* Ingredient Detail Modal */}
       {showDetail && (
        <TableModal
        id={ingredientId}
        onClose={() => setShowDetail(false)}
         
        />
      )}
    </>
  );
  

};



export default AddRecipeItem;