import React, { useRef, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import Category from "../categories/Category";
import AddCategory from "../categories/AddCategory";
import SupplierIngredients from "../supplierIngredients/SupplierIngredients";
import AddSupplierIngredient from "../supplierIngredients/AddSupplierIngredient";
import EditSupplierIngredient from "../supplierIngredients/EditSupplierIngredient";
import { FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { useParams } from "react-router-dom";
import AddStorageLocation from "../inventory/AddStorageLocation";
import StorageLocations from "../inventory/StorageLocations";
import { ToastContainer, toast } from "react-toastify";

const EditIngredientNewPage = () => {
  const { id: ing_id } = useParams();
  const [allValues, setAllValues] = useState();
  const [initialValues, setInitialValues] = useState({
    name: "",
    default_unit_measure: "",
    default_unit_price: "",
    category_id: "",
    par_quantity: "",
  });
  const [hasError, handleHttpRequest] = useHttp();
  const [inventoryCount, setInventoryCount] = useState();
  const [usageDetails,setusageDetails]=useState([]);
  const [selectedTypes, setSelectedTypes] = useState(["All"]);
  const [inventoryCountdetail, setInventoryCountdetail] = useState();
  const [qtyOrdered, setQtyOrdered] = useState();

  /** Add storage location for ingredients */
  const [ingLocations, setIngLocations] = useState([]);
  const setStorageLocation = (value) => {
    setIngLocations((prev) => [...prev, value]);
  };
  const removeStorageLocation = (id) => {
    const updatedItem = ingLocations.filter((inv) => inv.id !== id);
    setIngLocations(updatedItem);
  };
  // get ingredients //
  const getIngredient = async () => {
    // const response = await handleHttpRequest("ingredient/" + id);
    const response = await handleHttpRequest({
      page: "ingredient/" + ing_id,
      method: "GET",
    });
    if (!hasError) {
      // saveIngredient(response);
      setAllValues(response.message);
      setInitialValues({
        name: response.message.name,
        default_unit_measure: response.message.default_unit_measure,
        default_unit_price: response.message.default_unit_price,
        category_id: response.message.category_id,
        par_quantity: response.message.par_quantity,
      });
      setIngLocations(response.storage_location);
    }
  };
  const getUsage = async()=>{
    const response = await handleHttpRequest({
      page:"ingredientUsage/"+ing_id,
      method:"GET"
    });
    if(response.status==200){
      setusageDetails(response.usageDetails);
    }
  }

  // Add a static "All" to the list of unique types
const uniqueTypes = ["All", ...new Set(usageDetails.map((detail) => detail.type))];



// Handle checkbox toggle
const handleTypeToggle = (type) => {
  if (type === "All") {
    setSelectedTypes(["All"]); // Select only "All" when clicked
  } else {
    setSelectedTypes((prev) => {
      const updatedTypes = prev.includes(type)
        ? prev.filter((t) => t !== type) // Remove the type if already selected
        : [...prev.filter((t) => t !== "All"), type]; // Add type and remove "All" if selected

      // If no types are selected, return an empty array (no data)
      return updatedTypes.length === 0 ? [] : updatedTypes;
    });
  }
};

// Filter usageDetails based on selected types
const filteredDetails =
  selectedTypes.includes("All")
    ? usageDetails // Show all data if "All" is selected
    : selectedTypes.length > 0
    ? usageDetails.filter((detail) => selectedTypes.includes(detail.type))
    : []; // Show no data if no types are selected


  const getIngredientInventoryCount = async () => {
    const response = await handleHttpRequest({
      page: "ingredient-inventorycount/" + ing_id,
      method: "GET",
    });
    if (!response.status) {
      setInventoryCount(response);
    }
  };
  const getQtyOrdered = async () => {
    const response = await handleHttpRequest({
      page: "ingredient-purchaseorder/" + ing_id,
      method: "GET",
    });
    if (!response.status) {
      setQtyOrdered(response);
    }
  };
  const getInventorCountDetail = async () => {
    const response = await handleHttpRequest({
      page: "ingredient-inventorycountdetail/" + ing_id,
      method: "GET",
    });
    if (!response.status) {
      setInventoryCountdetail(response);
    }
  };
  useEffect(() => {
    getInventorCountDetail();
    getIngredient();
    getUsage();
    getIngredientInventoryCount();
    getQtyOrdered();
  }, []);

  const editIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "ingredient/edit/" + ing_id,
      method: "PUT",
      data: values,
    });
    response.status != 200
      ? toast.error("An error has occured while saving")
      : toast.info(response.message);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Inventory  Name is required.")
        .max(100, "Inventory  name is too long"),
      default_unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement for the Inventory Item."
      ),
      default_unit_price: Yup.number(
        "Default unit price must be a number"
      ).required("Last price is required."),
      category_id: Yup.number().required(
        "Select a category for the Inventory Item."
      ),
      par_quantity: Yup.number().notRequired(),
    }),
    onSubmit: (values) => {
      editIngredient({
        name: values.name,
        default_unit_measure: values.default_unit_measure,
        default_unit_price: values.default_unit_price,
        category_id: values.category_id,
        par_quantity: values.par_quantity,
        storage_locations: ingLocations,
      });
    },
  });

  /* Add Storage Modal */
  const [isAddStorageModalOpen, setIsAddStorageModalOpen] = useState(false);
  const [storageSaved, setStorageSaved] = useState(false);
  const addStorage = () => {
    setIsAddStorageModalOpen(true);
  };
  const closeAddStorage = () => {
    setIsAddStorageModalOpen(false);
  };
  const addStorageHandler = (message = null) => {
    setIsAddStorageModalOpen(false);
    if (message) {
      setStorageSaved((state) => !state);
      toast.info(message);
    }
  };

  /* Add Category Modal */
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [categorySaved, setCategorySaved] = useState(false);
  const addCategory = () => {
    setIsAddCategoryModalOpen(true);
  };
  const closeAddCategory = () => {
    setIsAddCategoryModalOpen(false);
  };
  const addCategoryHandler = (message, status) => {
    setIsAddCategoryModalOpen(false);
    if (status == 200) {
      toast.info(message);
      setCategorySaved((state) => !state);
    } else {
      toast.error(message);
    }
  };

  /* Add Supplier Ingredient for an Ingredient */
  const [isAddSupplierIngModalOpen, setIsAddSupplierIngModalOpen] =
    useState(false);
  const ingId = useRef(0);
  const openAddSupplierIngredient = (id) => {
    ingId.current = id;
    setIsAddSupplierIngModalOpen(true);
  };
  const closeAddSupplierIngredient = () => {
    setIsAddSupplierIngModalOpen(false);
  };
  const [supplierIngSaved, setSupplierIngSaved] = useState(false);
  const saveSuppIng = (message, status) => {
    setIsAddSupplierIngModalOpen(false);
    // status === 200 ? toast.info(message) : toast.error(message);
    if (status === 200) {
      toast.info(message);
      setSupplierIngSaved((state) => !state);
    } else {
      message?.map((msg) => {
        toast.error(msg);
      });
    }
  };

  /* Edit Supplier Ingredient for an Ingredient */
  const [isEditSupplierIngModalOpen, setIsEditSupplierIngModalOpen] =
    useState(false);
  const supplierIngId = useRef(0);
  const openEditSupplierIngredient = (id) => {
    supplierIngId.current = id;
    setIsEditSupplierIngModalOpen(true);
  };
  const closeEditSupplierIngredient = () => {
    setIsEditSupplierIngModalOpen(false);
  };

  const saveEditSuppIng = (message, status) => {
    setIsEditSupplierIngModalOpen(false);
    // status === 200 ? toast.info(message) : toast.error(message);
    if (status === 200) {
      toast.info(message);
      setSupplierIngSaved((state) => !state);
    } else {
      message?.map((msg) => {
        toast.error(msg);
      });
    }
    // setBEUpdated((state) => !state);
  };

  const [trackInventory, setTrackInventory] = useState(true);
  const setTrackInventoryHandler = () => {
    setTrackInventory((state) => !state);
  };
  return (
    <>
      {isEditSupplierIngModalOpen && (
        <EditSupplierIngredient
          supplierIngId={supplierIngId.current}
          saveClick={saveEditSuppIng}
          onClick={closeEditSupplierIngredient}
        />
      )}
      {isAddSupplierIngModalOpen && (
        <AddSupplierIngredient
          ingredient_id={ingId}
          saveClick={saveSuppIng}
          onClick={closeAddSupplierIngredient}
        />
      )}
      {isAddCategoryModalOpen && (
        <AddCategory
          saveClick={addCategoryHandler}
          onClick={closeAddCategory}
        />
      )}
      {isAddStorageModalOpen && (
        <AddStorageLocation
          saveClick={addStorageHandler}
          onClick={closeAddStorage}
        />
      )}

      <div className="card m-4">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title"> Inventory Detail</h4>
          </div>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs" id="ingredientTab" role="tablist">
            <li className="nav-item">
              <button
                className="nav-link active"
                id="inputs-tab"
                data-bs-toggle="tab"
                data-bs-target="#inputs-tab-pane"
                type="button"
                role="tab"
                aria-controls="inputs-tab-pane"
                aria-selected="true"
              >
                Inventory
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                id="supplier-tab"
                data-bs-toggle="tab"
                data-bs-target="#supplier-tab-pane"
                type="button"
                role="tab"
                aria-controls="supplier-tab-pane"
                aria-selected="false"
              >
                Supplier Spec
              </button>
            </li>
            <li className="nav-item">
            <button
              className="nav-link"
              id="usage-tab"
              data-bs-toggle="tab"
              data-bs-target="#usage-tab-pane"
              type="button"
              role="tab"
              aria-controls="usage-tab-pane"
              aria-selected="false"
            >
              Usage
            </button>
          </li>
          </ul>
          <div className="tab-content mt-4" id="ingredientTabContent">
            {/* Inputs Tab */}
            <div
              className="tab-pane fade show active"
              id="inputs-tab-pane"
              role="tabpanel"
              aria-labelledby="inputs-tab"
              tabIndex="0"
            >
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="name"
                          >
                            Item Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <fieldset>
                          <label htmlFor="category_id" className="lbltheme">
                          Inventory Category
                          </label>
                          <Category
                            categorySaved={categorySaved}
                            value={formik.values.category_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col d-flex align-self-end">
                        <button
                          type="button"
                          className="btn btn-link btn-just-icon"
                          id="AddIngredCatPop"
                          name="AddIngredCatPop"
                          onClick={addCategory}
                          title="New Inventory  Category"
                        >
                          <FaList className="themecolor" />
                        </button>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            htmlFor="default_unit_measure"
                            className="lblrequired lbltheme"
                          >
                            Unit of Measurement*
                          </label>
                          <select
                            className="form-select"
                            name="default_unit_measure"
                            id="default_unit_measure"
                            value={formik.values.default_unit_measure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Measurement</option>
                            {units.map((unit) => (
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            htmlFor="default_unit_price"
                            className="lblrequired lbltheme"
                          >
                            Last Price*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="default_unit_price"
                            name="default_unit_price"
                            value={formik.values.default_unit_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div id="qadd-ingred-errmsg">
                        {formik.errors.name ||
                          formik.errors.default_unit_measure ||
                          formik.errors.default_unit_price ||
                          formik.errors.category_id ||
                          null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row mb-3">
                      {/* Errors */}

                      {/* Inventory Tracking */}
                      <div className="row form-check form-switch m-2">
                        <div className="col-sm-4">
                          <input
                            className="form-check-input ckbtheme"
                            type="checkbox"
                            role="switch"
                            id="track_inventory"
                            checked={trackInventory}
                            onChange={setTrackInventoryHandler}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="track_inventory"
                          >
                            Track Inventory
                          </label>
                        </div>
                      </div>

                      {trackInventory && (
                        <div className="row mb-3">
                          {/* Inventory Fields */}

                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-6">
                                QTY IN STOCK: <span className="ms-2"><strong>{inventoryCount || 0}</strong></span>
                              </div>
                              <div className="col-sm-6 d-flex align-items-center">
                                QTY ON ORDER:
                                <span className="ms-2"><strong>{qtyOrdered || 0}</strong></span>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-sm-6">
                              <label
                                className="lbltheme"
                                htmlFor="par_quantity"
                              >
                                Par Qty
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="par_quantity"
                                name="par_quantity"
                                value={formik.values.par_quantity || 0}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="row mb-3">
                              <div className="col-sm-10">
                                <label
                                  className="lbltheme"
                                >
                                  Storage Locations
                                </label>
                                <StorageLocations
                                  setStorage={setStorageLocation}
                                  value=""
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  storageSaved={storageSaved}
                                />
                              </div>
                              <div className="col-sm-2 d-flex">
                                <button
                                  type="button"
                                  className="btn  btn-link btn-just-icon"
                                  onClick={addStorage}
                                >
                                  <FaList className="themecolor" />
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-10 d-flex flex-wrap">
                                {ingLocations.length > 0 &&
                                  ingLocations.map((ingLocation) => {
                                    const quantity =
                                      inventoryCountdetail?.[
                                        ingLocation.name
                                      ];

                                    return (
                                      <div
                                        key={ingLocation.id}
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          margin: "5px",
                                          borderRadius: "5px",
                                          backgroundColor: "#f8f9fa",
                                        }}
                                      >
                                        {ingLocation.name} ({quantity || 0})
                                        <span
                                          className="badge btntheme m-1"
                                          onClick={() =>
                                            removeStorageLocation(
                                              ingLocation.id
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          X
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex align-self-end justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* Supplier Ingredients Tab */}
            <div
              className="tab-pane fade"
              id="supplier-tab-pane"
              role="tabpanel"
              aria-labelledby="supplier-tab"
              tabIndex="0"
            >
              <SupplierIngredients
                ingUnit={initialValues.default_unit_measure}
                supplierIngSaved={supplierIngSaved}
                ingId={ing_id}
                onSupplierIngOpen={() => openAddSupplierIngredient(ing_id)}
                updateBD={() => {}}
                onSupplierIngEditOpen={openEditSupplierIngredient}
                onSuppIngEditSave={saveEditSuppIng}
              />
            </div>
                       {/* Usage Details Tab */}
                       <div
  className="tab-pane fade"
  id="usage-tab-pane"
  role="tabpanel"
  aria-labelledby="usage-tab"
  tabIndex="0"
>
  <h5 className="mb-3">Ingredient Usage Details</h5>
  {/* Checkbox Filter Section */}
  <div className="mb-3">
 
        {uniqueTypes.map((type) => (
          <div key={type} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id={`filter-${type}`}
              value={type}
              checked={selectedTypes.includes(type)}
              onChange={() => handleTypeToggle(type)}
            />
            <label className="form-check-label" htmlFor={`filter-${type}`}>
              {type}
            </label>
          </div>
        ))}
      </div>

      {/* Table */}
      <div className="col-sm-8">
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Measurement</th>
            </tr>
          </thead>
          <tbody>
            {filteredDetails.length > 0 ? (
              filteredDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{detail.name}</td>
                  <td style={{ textAlign: "right" }}>{detail.quantity}</td>
                  <td>{detail.measurement}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No usage data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
</div>

          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );

};

export default EditIngredientNewPage;
