import React from "react";

const SelectRecipe = ({ value, onChange, onBlur, setUnitMeasure, recipes = [] }) => {

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selectedRecipe = recipes.find((recipe) => recipe.id === +selectedId);
    
    if (selectedRecipe) {
      onChange(event); // Pass the change to parent
      setUnitMeasure(selectedRecipe.yeild_measure); // Set yield measure from selected recipe
    }
  };

  return (
    <select
      className="form-select"
      name="recipe_id"
      id="recipe_id"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
    >
      <option value="">Select recipes</option>
      {recipes.length === 0 ? (
        <option value="" disabled>No recipes found</option>
      ) : (
        recipes.map((recipe) => (
          <option key={recipe.id} value={recipe.id}>
            {recipe.name}
          </option>
        ))
      )}
    </select>
  );
};

export default React.memo(SelectRecipe);
