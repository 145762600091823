import React, { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";
import { FaExclamationCircle ,FaCheckCircle} from "react-icons/fa";
const MenuModal = ({ id, onClose }) => {
  const [menu, setMenu] = useState(null);
  const [cost, setCost] = useState(0);
  const [recipeItems, setRecipeItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [_, handleHttpRequest] = useHttp();

  const getRecipeDetails = async (recipeId) => {
    if (!recipeId) return;
    
    setLoading(true);
    setError(null);

    try {
      const response = await handleHttpRequest({
        page: `menuitem/recipes-ingredients/${recipeId}`,
        method: "GET",
      });

      const menuitem = await handleHttpRequest({
        page: `menuitem/${recipeId}`,
        method: "GET",
      });

      
      if (response && menuitem) {
        setRecipeItems(response.recipe_ing_list);
        setCost(response.total_cost);
       setMenu(menuitem);
      } else {
        setError("Failed to fetch recipe details.");
      }
    } catch (err) {
      setError("An error occurred while fetching Menu details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getRecipeDetails(id);
    }
  }, [id]);

  const closeModalOnClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex="-1"
      onClick={closeModalOnClickOutside}
      role="dialog"
      aria-labelledby="modal-title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content shadow-lg">
          <div className="modal-header bg-dark text-white">
            <h5 className="modal-title" id="modal-title">
              {loading ? "Loading..." : "Menu Detail"}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {loading ? (
              <div className="placeholder-glow">
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
                <div className="placeholder col-12 mb-2"></div>
              </div>
            ) : error ? (
              <div className="alert alert-danger text-center">{error}</div>
            ) : recipeItems ? (
              <>
              <div className="table-responsive mb-3">
              <table className="table table-bordered">
  <tbody>
    <tr>
      <th className="table-light" style={{ width: "30%" }}>Menu Name</th>
      <td>{menu.name || "N/A"}</td>
    </tr>
    <tr>
      <th className="table-light">Sales Price</th>
      <td>{`${menu.price || "N/A"}`}</td>
    </tr>
    <tr>
      <th className="table-light">Total Cost</th>
      <td>${cost.toFixed(2)}</td>
    </tr>
    <tr>
      <th className="table-light">Markup %</th>
      <td>
        {
          menu.price && menu.price !== 0 ? (
            (() => {
              const percentage = (cost / menu.price) * 100;
              const formattedPercentage = `${percentage.toFixed(2)}%`;
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {percentage > 33 ? (
                    <FaExclamationCircle style={{ color: 'red', marginRight: '5px' }} />
                  ) : (
                    <FaCheckCircle style={{ color: 'green', marginRight: '5px' }} />
                  )}
                  {formattedPercentage}
                </div>
              );
            })()
          ) : (
            "0.00%"
          )
        }
      </td>
    </tr>                      
  </tbody>
</table>

                </div>
                {/* Ingredient List */}
                {/* <h5 className="mb-3">Menu Items</h5> */}
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>type</th>
                        <th>Recipe/Ingredient</th>
                        <th>UOM</th>
                        <th>Quantity</th>                                               
                        <th>Cost</th>                   
                       
                      </tr>
                    </thead>
                    <tbody>
                      {recipeItems.length > 0 ? (
                        recipeItems.map((item) => (
                          <tr key={item.id}>
                            <td>{item.type}</td>
                            <td>{item.type==="Recipe"?item.recipe.name:item.ingredient.name}</td>
                            <td>{item.unit_measure}</td>
                            <td>{item.quantity}</td>
                            <td>${Number(item.cost).toFixed(2)}</td>
                            </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No Menu Items  found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <p className="text-center">No menu details available.</p>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
              aria-label="Close modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
