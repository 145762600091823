import React,  { useState, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import { getDataFromStore } from "../utils/indexdb";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const ingredientDB = 'ingredientDB';
const ingredienStore = 'ingredienStore';

const SelectIngredients = ({
  value,
  onChange,
  onBlur,
  setUnitMeasure,
  setIngredient,
}) => {
  const [ingredients, setIngredients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, handleHttpRequest] = useHttp();
  const isOnline = useNetworkStatus();

  const getOfflineInventoryData = async () => {
    setIsLoading(true);
    try {
      const data = await getDataFromStore(ingredientDB, ingredienStore);
      if (data && data.length > 0) {
        setIngredients(data);
      } else {
        setIngredients([]);
      }
    } catch (error) {
      console.error("Error fetching offline data:", error);
      setIngredients([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getIngredients = async () => {
    setIsLoading(true);
    try {
      const response = await handleHttpRequest({
        page: "ingredients",
        method: "GET",
      });
      if (!hasError && response) {
        setIngredients(response.message);
      }
    } catch (error) {
      console.error("Error fetching ingredients:", error);
      setIngredients([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOnline) {
      getOfflineInventoryData();
    } else {
      getIngredients();
    }
  }, [isOnline]);

  const handleChange = (event) => {
    console.log("event", event)
    const selectedId = event.target.value;
    const selectedIngredient = ingredients.find(
      (ingredient) => ingredient.id === +selectedId
    );
    console.log("selectedId", ingredients)
    console.log("selectedIngredient", selectedIngredient)
    if (selectedIngredient) {
      if (typeof onChange === 'function') {
        
        onChange(event);
      }
      if (typeof setUnitMeasure === 'function') {
        setUnitMeasure(selectedIngredient.default_unit_measure);
      }
      if (typeof setIngredient === 'function') {
        setIngredient(selectedIngredient);
      }
    }
  };

  return (
    <select
      className="form-select"
      name="ingredient_id"
      id="ingredient_id"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={isLoading}
    >
      <option value="">Select Ingredients</option>
      {isLoading ? (
        <option value="">Loading...</option>
      ) : !Array.isArray(ingredients) || ingredients.length === 0 ? (
        <option value="">No data available</option>
      ) : (
        ingredients.map((ingredient) => (
          <option key={ingredient.id} value={ingredient.id}>
            {ingredient.name}
          </option>
        ))
      )}
    </select>
  );
  
};

export default React.memo(SelectIngredients);