import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import SelectMenuItems from "../menuItems/SelectMenuItems";

const EditSaleItemModal = ({ sale_item_id, saveClick, cancleClick }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState({
    sale_id: "",
    menu_item_id: "",
    sold_qty: "",
    sold_price: "",
  });
  const [menuItems, setMenuItems] = useState([]);

  const EditSaleItem = async (values) => {
    const response = await handleHttpRequest({
      page: "sale-item/edit/" + sale_item_id,
      method: "PUT",
      data: {
        sale_id: values.sale_id,
        menu_item_id: values.menu_item_id,
        sold_qty: values.sold_qty,
        sold_price: values.sold_price,
        ext_total: values.sold_price * values.sold_qty,
      },
    });
    if (!response.status) {
      saveClick(response);
      // console.log("Saved ", response);
    } else {
      // console.log("Can't save ", response);
      saveClick(response.data);
    }
  };
  const closeModal = () => {
    formik.resetForm();
    cancleClick();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      menu_item_id: Yup.number().required("Select a Menu item."),
      sold_qty: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      sold_price: Yup.number()
        .typeError("Price must be a number")
        .required("Enter the price"),
    }),
    onSubmit: (values) => {
      EditSaleItem(values);
    },
  });

  const getSaleItem = async () => {
    const response = await handleHttpRequest({
      page: "sale-item/" + sale_item_id,
      method: "GET",
    });
    if (!response.status) {
      setInitialValues(response);
    }
  };
  const getMenuItems = async () => {
    const response = await handleHttpRequest({
      page: "menuitems",
      method: "GET",
    });
    if (!response.status) {
      setMenuItems(response);
    }
  };
  useEffect(() => {
    getMenuItems();
    getSaleItem();
  }, []);

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5
                  className="modal-title text-uppercase"
                  id="staticBackdropLabel"
                >
                  EDIT LINE ITEM
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form className="" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-sm-8">
                        <fieldset>
                          <label
                            htmlFor="menu_item_id"
                            className="lblrequired lbltheme"
                          >
                            Menu Item*
                          </label>
                          <select
                            className="form-select"
                            name="menu_item_id"
                            id="menu_item_id"
                            value={formik.values.menu_item_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option selected>Select Menu Item</option>
                            {menuItems.map((menuitem) => (
                              <option key={menuitem.id} value={menuitem.id}>
                                {menuitem.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-4">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="sold_qty"
                          >
                            Quantity Sold*
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="sold_qty"
                            name="sold_qty"
                            value={formik.values.sold_qty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-lg-3 col-sm-4">
                        <fieldset>
                          <label
                            htmlFor="sold_price"
                            className="lblrequired lbltheme"
                          >
                           Menu Item Price*
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="sold_price"
                            name="sold_price"
                            value={formik.values.sold_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-lg-3 col-sm-4">
                        <div>
                          <span>Extended Total</span>
                        </div>
                        <div>
                          <span>
                          ${
  Number(formik.values.sold_price) !== NaN && formik.values.sold_price !== ""
    ? Number(formik.values.sold_qty) !== NaN && formik.values.sold_qty !== ""
      ? (Number(formik.values.sold_qty) * Number(formik.values.sold_price)).toFixed(2)
      : Number(formik.values.sold_price).toFixed(2)
    : ""
}

                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div
                        id="qadd-ingred-errmsg"
                        className="col-sm-6 align-self-center"
                      >
                        {formik.errors.menu_item_id
                          ? formik.errors.menu_item_id
                          : formik.errors.sold_qty
                          ? formik.errors.sold_qty
                          : formik.errors.sold_price
                          ? formik.errors.sold_price
                          : null}
                      </div>
                      <div className="col-sm-6 d-flex align-self-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-link btn-just-icon"
                          id="SaveIngred"
                          name="SaveIngred"
                          title="Save"
                        >
                          <IoIosSave className="save-icon" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const EditSaleItem = (props) => {
  return ReactDOM.createPortal(
    <EditSaleItemModal
      sale_item_id={props.sale_item_id}
      saveClick={props.saveClick}
      cancleClick={props.cancleClick}
    />,
    document.getElementById("modal")
  );
};

export default EditSaleItem;
