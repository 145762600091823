import MainPanel from "../../components/MainPanel";
import { useFormik } from "formik";
import * as Yup from "yup";
import ListCardHeader from "../../templates/listpagetemplate/ListCardHeader";
import { MdOutlineCalculate } from "react-icons/md";
import Table from "../../components/Table";
import { useRef, useState, useContext, useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import { FaCartPlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import DiscardOnHandPrompt from "./DiscardOnHandPrompt";
import AuthContext from "../../store/LoginContext";

const GenerateInventoryReOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const [ingredientsReOrder, setIngredientsReOrder] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [discandOnHandPrompt, setDiscandOnHandPrompt] = useState();
  const [conversionRates, setConversionRates] = useState([]); // State for conversion rates
  const [activeTab, setActiveTab] = useState("inventory"); // Track active tab
  const [filterValue, setFilterValue] = useState({
    salesforecast: "",
    discardOnHandQty: null,
  });
  const message = useRef();
  const { updateNotification } = useContext(AuthContext);

  // Fetch conversion rates on component mount
  useEffect(() => {
    const fetchConversionRates = async () => {
      const response = await handleHttpRequest({
        page: "unitconversion",
        method: "GET",
      });
      if (response.status==200) {
        setConversionRates(response.data);
      } else {
        toast.error("Failed to load conversion rates.");
      }
    };
    fetchConversionRates();
  }, []);
  
  

  const generateInvReorder = async (values) => {
    const response = await handleHttpRequest({
      page: "inventory-reorder",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      setIngredientsReOrder(response);
    } else if (response.status === 403) {
      setDiscandOnHandPrompt(true);
      message.current = response.data;
    } else {
      toast.error(response.data);
    }
  };

  const handleCanclePrompt = () => {
    setDiscandOnHandPrompt(false);
  };

  const handleConfirmPrompt = () => {
    setDiscandOnHandPrompt(false);
    setFilterValue((prev) => ({ ...prev, discardOnHandQty: true }));
    generateInvReorder({ ...filterValue, discardOnHandQty: true });
  };

  const loadCart = async () => {
    const response = await handleHttpRequest({
      page: "cart",
      method: "POST",
      data: { items: ingredientsReOrder },
    });

    if (!response.status) {
      const notificationResponse = await handleHttpRequest({
        page: "cart-qty",
        method: "GET",
      });
      !notificationResponse.status && updateNotification(notificationResponse);
      toast.info(response);
    } else {
      toast.error(response);
    }
  };

  const formik = useFormik({
    initialValues: filterValue,
    validationSchema: Yup.object({
      salesforecast: Yup.number().required("Enter sales forecast"),
    }),
    onSubmit: (values) => {
      setFilterValue(values);
      generateInvReorder(values);
    },
  });

  const COLUMNS = [
    { Header: "Inventory Item", accessor: "name" },
    {Header:"UoM", accessor:"unit_measure"},
    {
      Header: "Required Quantity",
      accessor: "qtyRequired",
      headerAlign:"center",
      textAlign:"right",
      // Cell: ({ cell }) =>
      //   cell ? cell.value + " " + cell.row.original.unit_measure : "",
    },
    { Header: "On Hand", accessor: "qtyOnHand" , 
      headerAlign:"center",
      textAlign:"right",},
    { Header: "Par Quantity", 
      headerAlign:"center",
      textAlign:"right",
      accessor: "par" ,
      Cell: ({ value }) => value === null ? 0 : value, // If value is null, return 0
    },
    { Header: "Order Quantity", accessor: "quantity_to_order", 
      headerAlign:"center",
      textAlign:"right", },
    {
      Header: "Supplier Type",
      accessor: "supplierType",
      Cell: ({ value }) =>
        value === "No supplier found" ? (
          <div className="error-msg">{value}</div>
        ) : (
          value
        ),
    },
    { Header: "Supplier Name", accessor: "supplierName" },
    { Header: "Supplier's Description", accessor: "supplierIngDescription" },
    {
      Header: "Order Qty in Supplier UOM",
      accessor: "qty_persellby",
      textAlign:"right",
      Cell: ({ cell }) =>
        cell ? cell.value + " " + cell.row.original.supplierSellBy : "",
    },
    {
      Header: "Unit Price",
      accessor: "pricePerSellBy",
      headerAlign:"center",
      textAlign:"right",
      Cell: ({ value }) => (value ? "$" + value : ""),
    },
    {
      Header: "Extended Price",
      accessor: "extendedPrice",
      headerAlign:"center",
      textAlign:"right",
      Cell: ({ value }) => (value ? "$" + value : ""),
    },
    { Header: "Note", accessor: "flag" },
  ];

  const CONVERSION_COLUMNS = [
    { Header: "Source", accessor: "unit" ,disableSortBy: true, },
    { Header: "Destination", accessor: "distination",disableSortBy: true, },
    { Header: "Rate", accessor: "rate" ,disableSortBy: true,headerAlign:"center",textAlign:"right",},
  ];

  return (
    <>
      {discandOnHandPrompt && (
        <DiscardOnHandPrompt
          message={message.current}
          onCancle={handleCanclePrompt}
          onConfirm={handleConfirmPrompt}
        />
      )}
      <MainPanel>
        <div className="card">
          <ListCardHeader title="Generate Inventory Reorder"></ListCardHeader>
          <div className="card-body">
          <ul className="nav nav-tabs" role="tablist">
  <li
    className={`nav-item ${activeTab === "inventory" ? "active" : ""}`}
    role="presentation"
  >
    <button
      className="nav-link"
      role="tab"
      aria-selected={activeTab === "inventory"}
      aria-controls="forecasting-tab"
      onClick={() => setActiveTab("inventory")}
      id="forecasting-tab-button"
    >
      Forecasting
    </button>
  </li>
  <li
    className={`nav-item ${activeTab === "conversions" ? "active" : ""}`}
    role="presentation"
  >
    <button
      className="nav-link"
      role="tab"
      aria-selected={activeTab === "conversions"}
      aria-controls="conversions-tab"
      onClick={() => setActiveTab("conversions")}
      id="conversions-tab-button"
    >
      Unit Conversions
    </button>
  </li>
</ul>


            {activeTab === "inventory" && (
              <>
                <form className="" onSubmit={formik.handleSubmit}>
                  <div className="row mt-3">
                    <div className="col-sm-3">
                      <label
                        className="lblrequired lbltheme"
                        htmlFor="salesforecast"
                      >
                        Sales Forecast
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          className="form-control"
                          name="salesforecast"
                          value={formik.values.salesforecast}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="col-sm-1 d-flex align-items-end">
                      <button
                        type="submit"
                        className="btn btn-link btn-just-icon"
                        name="calculate"
                        title="Calculate"
                      >
                        <MdOutlineCalculate className="themecolor fs-2" />
                      </button>
                    </div>
                    <div className="col-sm-2 d-flex align-items-end">
                      {ingredientsReOrder.length !== 0 && (
                        <button
                          type="button"
                          className="btn btntheme"
                          name="loadcart"
                          title="Load Cart"
                          onClick={loadCart}
                        >
                          <FaCartPlus className="fs-4" />
                          Load Cart
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 error-msg mt-2">
                      {formik.errors.salesforecast
                        ? formik.errors.salesforecast
                        : null}
                    </div>
                  </div>
                </form>
                <hr />
                <Table columns={COLUMNS} data={ingredientsReOrder} />
              </>
            )}

{activeTab === "conversions" && (
  <div className="row mt-3">
    <div className="col-sm-6">
      <h4 className="text-center mb-4">Conversion Rates</h4>
      <Table columns={CONVERSION_COLUMNS} data={conversionRates} />
    </div>
    <div className="col-sm-4">
     
      
    </div>
  </div>
)}


          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

export default GenerateInventoryReOrder;
