import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import DeleteModal from "../DeleteModal";
import { IoIosSave } from "react-icons/io";
import { FaExclamationCircle, FaCheckCircle , FaList } from "react-icons/fa";
import MenuItemRecipeIngredient from "./MenuItemRecipeIngredient";
import AddRecipeIngredient from "./AddRecipeIngredient";
import EditRecipeIngredient from "./EditRecipeIngredient";
import MenuGroup from "../menugroup/MenuGroups";
import AddMenuGroup from "../menugroup/AddMenuGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditMenuItem = () => {
  const [_, handleHttpRequest] = useHttp();
  const { id: menuitem_id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: "",
    menu_group_id: "",
  });
  const [totalCost, setTotalCost] = useState(0);

  const setTotalCostIngRec = (value) => {
    setTotalCost(value);
  };
  /** Get Menu Item */
  const getMenuItem = async () => {
    const response = await handleHttpRequest({
      page: "menuitem/" + menuitem_id,
      method: "GET",
    });
    console.log("Response:", response); // Debugging: Check if response contains expected data
    if (response && response.status) { // Ensure response has data
      setInitialValues({
        name: response.name || "", // Fallback to empty string
        price: response.price || "",
        menu_group_id: response.menu_group_id || "",
      });
    }
  };
  useEffect(() => {
    if (menuitem_id) {
      getMenuItem();
    }
  }, [menuitem_id]);

  /* Edit Menu Item */
  const editMenuItem = async (values) => {
    const response = await handleHttpRequest({
      page: "menuitem/edit/" + menuitem_id,
      method: "PUT",
      data: values,
    });
    if (!response.status) {
      toast.info(response);
    } else {
      toast.info(response);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Menu item name is required.")
        .max(100, "Menu item name is too long"),
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Enter the price"),
      menu_group_id: Yup.number().required("Select a menu group for the menu."),
    }),
    onSubmit: (values) => {
      editMenuItem(values);
    },
  });
  
 
 

  /** Menu Group */
  const [isAddMenuGroupOpen, setIsAddMenuGroupOpen] = useState(false);
  const [menuGroupUpdated, setMenuGroupUpdated] = useState(false);
  const toggleAddMenuGroup = () => {
    setIsAddMenuGroupOpen((state) => !state);
  };
  const handleAddMenuGroup = (message) => {
    setIsAddMenuGroupOpen(false);
    setMenuGroupUpdated((state) => !state);
    // toast.info(message);
    toast(<ToastBody message={message} />);
  };
  // console.log("Formik values:", formik.values);
  return (
    <>
      <MainPanel>
        {isAddMenuGroupOpen && (
          <AddMenuGroup
            saveClick={handleAddMenuGroup}
            onClick={toggleAddMenuGroup}
          />
        )}
       
        
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ textTransform: "uppercase" }}
              >
                {initialValues.name}
              </h5>
            </div>
          </div>
          <div className="">
            <div className="modal-body row g-3" style={{ padding: "1em 2em" }}>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-2">
                    <fieldset>
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Menu Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-2">
                    <fieldset>
                      <label htmlFor="menu_group_id" className="lbltheme">
                        Menu Group
                      </label>
                      <MenuGroup
                        onMenuGroupSave={menuGroupUpdated}
                        value={formik.values.menu_group_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-1 d-flex align-self-end">
                    <button
                      type="button"
                      className="btn btn-link btn-just-icon"
                      id="AddMenuGroupPop"
                      name="AddMenuGroupPop"
                      onClick={toggleAddMenuGroup}
                      title="New Menu Group"
                    >
                      <FaList className="themecolor" />
                    </button>
                  </div>

                  <div className="col-sm-1">
                    <fieldset>
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        value={formik.values.price ? parseFloat(formik.values.price).toFixed(2) : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-1 d-flex align-self-end">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                  <div className="col-sm-2 text-center">
                    <div className="fw-bold">Total Cost </div>
                    <h4 className="themecolor">
                      ${!isNaN(totalCost) && totalCost !== undefined ? totalCost.toFixed(2) : "0.00"}
                    </h4>

                  </div>

<div className="col-sm-2 text-center">
  <div className="fw-bold">Markup %</div>
  <h4 className={`themecolor ${!isNaN(totalCost) && formik.values.price && parseFloat(formik.values.price) > 0 
      ? (totalCost / parseFloat(formik.values.price)) * 100 > 33 
        ? "text-danger fw-bold"  // Apply red color + bold text when > 33%
        : "text-success fw-bold"  // Apply green color + bold text when <= 33%
      : ""}`}
  >
    {/* Display Icon Before Percentage */}
    { !isNaN(totalCost) && formik.values.price && parseFloat(formik.values.price) > 0 
      ? ( (totalCost / parseFloat(formik.values.price)) * 100 > 33 
          ? <FaExclamationCircle style={{ color: 'red', marginRight: '5px' }} />
          : <FaCheckCircle style={{ color: 'green', marginRight: '5px' }} />
        )
      : null
    }

    {/* Display Percentage Value */}
    {!isNaN(totalCost) && formik.values.price && parseFloat(formik.values.price) > 0 
      ? ((totalCost / parseFloat(formik.values.price)) * 100).toFixed(2) + "%" 
      : "0.00%"}
  </h4>
</div>


                </div>
               
               
               
                <div className="row mt-3">
                  <div id="qadd-ingred-errmsg" className="error-msg">
                    {formik.errors.name
                      ? formik.errors.name
                      : formik.errors.price
                      ? formik.errors.price
                      : formik.errors.menu_group_id
                      ? formik.errors.menu_group_id
                      : null}
                  </div>
                </div>
              </form>
              <hr></hr>
              <div>
                
                <div className="row">
                  

                <AddRecipeIngredient
            menuitem_id={menuitem_id}
            setTotalCostIngRec={setTotalCostIngRec}
           
          />
                
                </div>
              </div>
              <div>
                
              </div>
            </div>
          </div>
        </div>
      </MainPanel>
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
};

const ToastBody = ({ closeToast, toastProps }) => {
  <div>
    <h2>Notification</h2>
    <p>{toastProps.message}</p>
    {toastProps.position}
    {/* <button>Retry</button> */}
    <button onClick={closeToast}>close</button>
  </div>;
};

export default EditMenuItem;
