import { useEffect, useState, useRef } from "react";
import useHttp from "../../hooks/useHttp";
import AddRecipe from "./AddRecipe";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import SetStatusModal from "../SetStatusModal";
import ImportRecipes from "../imports/ImportRecipes";
import { ToastContainer, toast } from "react-toastify";
import RecipeModal from "../../components/RecipeModal";

const Recipes = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const [recipes, setRecipes] = useState([]);

  const [beUpdated, setBEUpdated] = useState(false);

  const getRecipes = async () => {
    // const response = await handleHttpRequest('recipes');
    const response = await handleHttpRequest({
      page: "recipes",
      method: "GET",
    });
    if (!hasError) {
      setRecipes(response);
    }
  };

  useEffect(() => {
    getRecipes();
  }, [beUpdated]);

  const COLUMNS = [
    {
      Header: "Recipe Name",
      accessor: "name",
    },
    {
      Header: "Recipe Yields",
      accessor: "recipe_yeilds",
      // Cell: ({value}) => formatPhoneNumber(value)
    },
    {
      Header: "Recipe Cost",
      accessor: "recipe_cost",
      align: "right",
      Cell: ({ value }) =>
        value !== undefined && !isNaN(value)
          ? `$${Number(value).toFixed(2)}`
          : "$0.00", // Fallback if value is invalid or undefined
    },
    
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            cell.value ? "text-bg-primary" : "text-bg-danger"
          }`}
          style={{ color: "#000" }}
        >
          {cell.value == 1 ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-recipe/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          {/* <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button> */}
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeactivateModal(cell.value, cell.row.original.status);
            }}
          >
            <MdOutlineDoNotDisturbAlt />
          </button>
        </div>
      ),
    },
  ];

  const recipeId = useRef(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveRecipe = (response) => {
    setIsAddModalOpen(false);
    // data[1] === 200 ? toast.info(data[0]) : toast.error(data[0]);
    toast.info(response);
    setBEUpdated((state) => !state);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (response) => {
    // console.log(response);
    setIsDeleteModalOpen(false);
    // notificationMessage.current = response[0];
    // notificationStatus.current = response[1];
    // setToast(true);
    // setToastUpdated((state) => !state);

    response[1] === 200 ? toast.info(response[0]) : toast.error(response[0]);
    setBEUpdated((state) => !state);
  };

  /** Deactivate Modal   */
  const deactivateIdIs = useRef(0);
  const recipeStatus = useRef();
  const [isdeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const openDeactivateModal = (id, status) => {
    deactivateIdIs.current = id;
    recipeStatus.current = status;
    setIsDeactivateModalOpen((state) => !state);
  };
  const deactivateHandler = (response) => {
    setIsDeactivateModalOpen(false);
    response[1] === 200 ? toast.info(response[0]) : toast.error(response[0]);
    setBEUpdated((state) => !state);
  };

  /* Import Modal */
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImport = (type) => {
    setIsImportModalOpen((state) => !state);
  };
  const handleImportResponse = (response) => {
    setIsImportModalOpen(false);
    // toast.info(response);
    // setIsSalesUpdated((state) => !state);
  };
  const handleErrorResponse = (message) => {
    setIsImportModalOpen(false);
    // toast.error(message);
  };

  return (
    <>
      <MainPanel>
        {isImportModalOpen && (
          <ImportRecipes
            onClose={toggleImport}
            onImport={handleImportResponse}
            onError={handleErrorResponse}
          />
        )}
        {isAddModalOpen && (
          <AddRecipe saveClick={saveRecipe} onClick={openAddModal} />
        )}
        {/* {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="recipe"
            name="recipe"
          />
        )} */}
        {isdeactivateModalOpen && (
          <SetStatusModal
            currentStatus={recipeStatus.current}
            itemId={deactivateIdIs.current}
            onClick={openDeactivateModal}
            onToggle={deactivateHandler}
            item="recipe"
            name="recipe"
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                RECIPES
                <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddModal}
                >
                  <FaPlus />
                </button>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={recipes} columns={COLUMNS} ModalComponent={RecipeModal} />
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar />
      </MainPanel>
    </>
  );
};

export default Recipes;
