import React from "react";

const SelectIngredient = ({
  value,
  onChange,
  onBlur,
  setUnitMeasure,
  setIngredient,
  ingredients = [], // Accept ingredients as a prop
}) => {

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selectedIngredient = ingredients.find(
      (ingredient) => ingredient.id === +selectedId
    );
    
    if (selectedIngredient) {
      if (typeof onChange === 'function') {
        onChange(event);
      }
      if (typeof setUnitMeasure === 'function') {
        setUnitMeasure(selectedIngredient.default_unit_measure);
      }
      if (typeof setIngredient === 'function') {
        setIngredient(selectedIngredient);
      }
    }
  };

  return (
    <select
      className="form-select"
      name="ingredient_id"
      id="ingredient_id"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
    >
      <option value="">Select Ingredients</option>
      {ingredients.length === 0 ? (
        <option value="">No data available</option>
      ) : (
        ingredients.map((ingredient) => (
          <option key={ingredient.id} value={ingredient.id}>
            {ingredient.name}
          </option>
        ))
      )}
    </select>
  );
};

export default React.memo(SelectIngredient);
