import React, { useCallback,useEffect,useState, useMemo } from "react";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { IoIosSave, IoIosCreate, IoIosTrash, IoIosAdd } from "react-icons/io";
import SelectIngredient from './SelectIngredients';
import SelectRecipe from "./SelectRecipe";
import useConversion from "../../hooks/useConversion";
import units from "../../utils/units";
import useHttp from "../../hooks/useHttp";
import TableModal from "../../components/TableModal";
import RecipeModal from "../../components/RecipeModal";


const AddRecipeIngredientModal = ({ menuitem_id,setTotalCostIngRec }) => {
  const [hasError, handleHttpRequest] = useHttp();
  const [IsStandardUnit] = useConversion();

  // const [__, convertUnit] = useUnitConversion();
  
    const [ingredients, setIngredients] = useState([]);
  
  const [rows, setRows] = useState([]);
  const [newRowId, setNewRowId] = useState(0);
  const [recipes, setRecipes] = useState([]);

  const getRecipes = async () => {
    // const response = await handleHttpRequest('recipes')
    const response = await handleHttpRequest({
      page: "recipes",
      method: "GET",
    });
    if (!hasError) {
      setRecipes(response);
    }
  };
  const getIngredients = async () => {    
    try {
      const response = await handleHttpRequest({
        page: "ingredients",
        method: "GET",
      });
      if (!hasError && response) {
        setIngredients(response.message);
      }
    } catch (error) {
      console.error("Error fetching ingredients:", error);
      setIngredients([]);
    } finally {    
    }
  };
  const getMenuItem = useCallback(async () => {
    try {
      const response = await handleHttpRequest({
        page: `menuitem/recipes-ingredients/${menuitem_id}`,
        method: "GET",
      });
  
      console.log("Response:", response); // Debugging: Check if response contains expected data
  
      if (response ) {
        const savedItems = response.recipe_ing_list;
        if (!savedItems || savedItems.length === 0) return ;
  
        // Format the data for the table
        const formattedRows = savedItems.map(item => ({
          id: item.id,
          type: item.type,
          item_id: item.type === "Ingredient" ? item.ingredient.id : item.recipe.id,
          item_name: item.type === "Ingredient" ? item.ingredient?.name : item.recipe?.name,
          item_measure:item.type === "Ingredient" ? item.ingredient.default_unit_measure : item.recipe.unit_measure,
          quantity: item.quantity,
          unit_measure: item.unit_measure,
          cost: item.cost,
          isEditing: true,
          isNew: false,
        }));
  
        // Update the table rows
        setRows(formattedRows);
        setTotalCostIngRec(response.total_cost);
      }
    } catch (error) {
      console.error("Error fetching menu item:", error);
    }
  }, [menuitem_id, handleHttpRequest, setTotalCostIngRec]); // Include all dependencies
  
  useEffect(() => {
    if (menuitem_id) {
      getMenuItem();
      getIngredients();
      getRecipes();
    }
  }, [menuitem_id]);
  const [showDetail, setShowDetail] = useState(false);
  const [ingredientId, setIngredientId] = useState(null);
  const [ingtype, setType] = useState(null);

  const handleClick = (id,type) => {
    setIngredientId(id);
    setType(type);
    setShowDetail(true);  // Show the ingredient detail when button is clicked
  };
  
  const QuantityCell = React.memo(({ row, handleQuantityChange }) => {
    const [localQuantity, setLocalQuantity] = useState(row.original.quantity);
  
    const handleBlur = async () => {
      await handleQuantityChange(row.original.id, localQuantity);
    };
  
    return (
      <input
        className="form-control"
        value={localQuantity}
        onChange={(e) => setLocalQuantity(e.target.value)}
        onBlur={handleBlur}
      />
    );
  });
  const columns = useMemo(() => [
    {
      accessorKey: "type",
      header: "Type",
      cell: ({ row }) => (
        <select
          value={row.original.type}
          onChange={(e) => handleTypeChange(row.original.id, e.target.value)}
          disabled={!row.original.isEditing}
          className="form-select"
        >
          <option value="Recipe">Recipe</option>
          <option value="Ingredient">Ingredient</option>
        </select>
      ),
    },
    {
      accessorKey: "item",
      header: "Recipe/Ingredient",
      cell: ({ row }) => row.original.isEditing ? (
        row.original.type === "Recipe" ? (
          <SelectRecipe
            value={row.original.item_id}
            setUnitMeasure={(unit) => handleUnitChange(row.original.id, unit)}
            onChange={(e) => handleItemChange(row.original.id, e.target.value)}
            recipes={recipes}
          />
        ) : (
          <SelectIngredient
            value={row.original.item_id}
            setUnitMeasure={(unit) => handleUnitChange(row.original.id, unit)}
            onChange={(e) => handleItemChange(row.original.id, e.target.value)}
            ingredients={ingredients}  // Ensure ingredients is passed here
          />
        )
      ) : (
        <span>
          {row.original.item_name || "N/A"}
        </span>
      )
    }
,    
    
    {
      accessorKey: "unit_measure",
      header: "UOM",
      cell: ({ row }) => {
        const currentUnit = row.original.unit_measure;
        const isStandard = IsStandardUnit(currentUnit) === "Y";
        
        return row.original.isEditing ? (
          <select
            value={currentUnit}
            onChange={(e) =>{ e.stopPropagation(); handleUnitChange(row.original.id, e.target.value)}}
            className="form-select"
          >
            <option value="">Select Unit</option>
            {isStandard ? (
              units.map(unit => (
                <option key={unit.id} value={unit.unit}>{unit.name}</option>
              ))
            ) : (
              units.filter(unit => unit.unit === currentUnit).map(unit => (
                <option key={unit.id} value={unit.unit}>{unit.name}</option>
              ))
            )}
          </select>
        ) : (
          <span>{units.find(u => u.unit === currentUnit)?.name}</span>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      cell: ({ row }) =>
        row.original.isEditing ? (
          <QuantityCell row={row} handleQuantityChange={handleQuantityChange} />
        ) : (
          <span>{row.original.quantity}</span>
        ),
    },
    
    {
      accessorKey: "cost",
      header: "Cost",
      cell: ({ row }) => {
        const cost = row.original.cost;
        // Default to 0.00 if cost is undefined or NaN
        const formattedCost = cost !== undefined && !isNaN(cost) 
          ? `$${Number(cost).toFixed(2)}` 
          : "$0.00";
        return <span>{formattedCost}</span>;
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div className="d-flex gap-2">
          {row.original.isNew ? (
            <>
              <button
                className="btn btn-sm btn-success"
                onClick={() => handleSaveRow(row.original.id)}
              >
                <IoIosSave />
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleCancel(row.original.id)}
              >
                ✕
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => toggleEdit(row.original.id)}
              >
                <IoIosCreate />
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleDeleteRow(row.original.id)}
              >
                <IoIosTrash />
              </button>
              <button 
  className="btn btntheme" 
  onClick={() => handleClick(row.original.item_id,row.original.type)}
>
  Detail
</button>

            </>
          )}
        </div>
      ),
    },
  ], [IsStandardUnit]);
  const rowsMemo = useMemo(() => rows, [rows]);
  const table = useReactTable({
    data: rowsMemo,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const addNewRow = () => { 
    
    const newRow = {
      id: newRowId,
      type: "Recipe",
      item_id: "",
      item_name: "", // Ensure this is added for display
      item_measure: "",
      quantity: "",
      unit_measure: "",
      cost: 0, // Default cost to 0
      isEditing: true,
      isNew: true,
    };
    
    setRows([...rows, newRow]);
    setNewRowId(prev => prev + 1);
  };
  
  

  const handleSaveRow = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    if (!row.item_id || !row.quantity || !row.unit_measure) {
      alert("Please fill all required fields");
      return;
    }

    try {
      const endpoint = row.type === "Recipe" 
        ? "menuitem-recipe/add" 
        : "menuitem-ingredient/add";
      
      const response = await handleHttpRequest({
        page: endpoint,
        method: "POST",
        data: {
          [row.type === "Recipe" ? "recipe_id" : "ingredient_id"]: row.item_id,
          menu_item_id: menuitem_id,
          quantity: row.quantity,
          unit_measure: row.unit_measure,
        },
      });

      if (response) {
        getMenuItem(); // Refresh list
      } else {
        alert("Failed to save item.");
      }
    } catch (error) {
      alert("Error saving item");
    }
};


  const handleDeleteRow = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    console.log(rows);
    if (row.isNew) {
      setRows(prev => prev.filter(r => r.id !== rowId));
      return;
    }

    try {
      const endpoint = row.type === "Recipe" 
      ? `menuitem-recipe/${row.id}`  // For Recipe
      : `menuitem-ingredient/${row.id}`;  // For Ingredient
      
      await handleHttpRequest({
        page: endpoint,
        method: "DELETE",
        
      });
      getMenuItem();
    } catch (error) {
      alert("Error deleting item");
    }
  };

  const toggleEdit = async (rowId) => {
    const row = rows.find(r => r.id === rowId);
    console.log(rows);
    if (row.isNew) {
      setRows(prev => prev.filter(r => r.id !== rowId));
      return;
    }

    try {
      const endpoint = row.type === "Recipe" 
        ? `menuitem-recipe/edit/${row.id}`  // For Recipe
        : `menuitem-ingredient/edit/${row.id}`;  // For Ingredient
      
      const requestData = {
        menu_item_id: menuitem_id,
        quantity: row.quantity,
        unit_measure: row.unit_measure,
      };
    
      // Conditionally add either 'recipe_id' or 'ingredient_id' based on row.type
      if (row.type === "Recipe") {
        requestData.recipe_id = row.item_id;  // Add recipe_id for Recipe
      } else if (row.type === "Ingredient") {
        requestData.ingredient_id = row.item_id;  // Add ingredient_id for Ingredient
      }
    
      // Sending the request
     const response= await handleHttpRequest({
        page: endpoint,
        method: "PUT",
        data: requestData,
      });
    if(response){
      getMenuItem();
    }
    } catch (error) {
      alert("Error updating item");
    }
    
  };
  const handleCancel = useCallback((rowId) => {
    setRows((prev) => prev.filter(row => 
      row.isNew ? row.id !== rowId : true
    ));
  }, []);
  
  const handleTypeChange = useCallback((rowId, type) => {
    setRows((prev) => prev.map(row => 
      (row.id === rowId && row.type !== type) ? { ...row, type, item_id: "", unit_measure: "" } : row
    ));
  }, []);
  
  const handleItemChange = useCallback((rowId, item_id) => {
    setRows((prev) => prev.map(row => 
      (row.id === rowId && row.item_id !== item_id )? { ...row, item_id } : row
    ));
  }, []);
  
  const handleUnitChange = useCallback((rowId, unit_measure) => {
    setRows((prev) => prev.map(row => 
      (row.id === rowId && row.unit_measure !== unit_measure) ? { ...row, unit_measure } : row
    ));
  }, []);
  
  const handleQuantityChange = useCallback((rowId, quantity) => {
    setRows((prev) => prev.map(row => 
      (row.id === rowId && row.quantity !== quantity) ? { ...row, quantity } : row
    ));
  }, []);

// Pass rowsMemo to child components

  
  return (
    <>
    <div className="modal-body">
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className={row.original.isNew ? "table-light" : "table-light"}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td colSpan={columns.length}>
                <div className="d-flex justify-content-start mb-3">
                  <button className="btn btn-primary" onClick={addNewRow} disabled={rows.some(row => row.isNew || rows.length >= 10)}>
                    <IoIosAdd /> Add Row
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>     
    </div>
     
       {
  showDetail && ingtype === "Ingredient" ? (
    <TableModal
      id={ingredientId}
      onClose={() => setShowDetail(false)}
    />
  ) : showDetail && ingtype !== "Ingredient" ? (
    <RecipeModal
      id={ingredientId}
      onClose={() => setShowDetail(false)}
    />
  ) : null
}

    </>
     
  );
};

export default AddRecipeIngredientModal;
