const useUnitConversion = () => {
  // Global conversion values of Ounce (oz)
  const conversionRates = {
    tsp: 6,
    tbsp: 2,
    ml: 29.57353193,
    c: 0.120095,
    pt: 0.0625,
    qt: 0.03125,
    l: 0.0295735,
    gal: 0.0078125,
    g: 28.3495,
    kg: 0.0283495,
    lb: 0.0625,
    "fl oz": 0.958611418535,
    oz: 1,
  };

  const IsStandardUnit = (unit) => {
    if (!unit || typeof unit !== "string") return false;
    return conversionRates[unit.toLowerCase()] !== undefined;
  };

  const convertUnit = (value, fromUnit, toUnit) => {
    if (typeof value !== "number" || isNaN(value)) return null;
    if (!fromUnit || !toUnit || typeof fromUnit !== "string" || typeof toUnit !== "string") return null;

    const funit = fromUnit.toLowerCase();
    const tunit = toUnit.toLowerCase();

    // Check if both units are standard
    if (!IsStandardUnit(funit) || !IsStandardUnit(tunit)) return null;

    // If the units are the same, return the original value
    if (funit === tunit) return value;

    // Convert from source unit to ounces
    const valueInOunce = funit === "oz" ? value : value / conversionRates[funit];

    // Convert from ounces to the target unit
    return tunit === "oz" ? valueInOunce : valueInOunce * conversionRates[tunit];
  };

  return [IsStandardUnit, convertUnit];
};

export default useUnitConversion;
